import React, { useState } from 'react';
import { Container, Button2 } from '../../../globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  InfoColumn2,
  TextWrapper,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from './InfoSection.elements';
import styled from 'styled-components';

// Estilos adicionales
const PlayIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const PlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  width: 100px; /* Tamaño del contenedor redondo */
  height: 100px;
  background-color: gray; /* Fondo gris */
  border-radius: 50%; /* Hace que sea redondo */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Evita interferencia con el hover */

  ${PlayIconWrapper}:hover & {
    opacity: 1; /* Muestra el ícono al pasar el mouse */
  }

 /*  &::before {
    content: '▶'; /* Ícono de reproducción */
    color: white; /* Color blanco para el ícono */
    font-size: 30px; /* Tamaño del ícono */
  } */
`;

const StyledImg = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  ${PlayIconWrapper}:hover & {
    transform: scale(1.05); /* Zoom suave al hacer hover */
  }
`;

const InfoSection = ({
  primary,
  lightBg,
  imgStartg,
  lightTextDesc,
  buttonLabel,
  description,
  headline,
  lightText,
  img,
  alt,
  primary2,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  /*G.A.*/
  const onClickYoutube = () => {
    setIsModalOpen(true); // Abre el modal al hacer clic
  };

  const closeModal = () => setIsModalOpen(false);

  // Cierra el modal al hacer clic en el fondo
  const handleClickOutside = (e) => {
    if (e.target.id === 'modalBackground') {
      closeModal();
    }
  };

  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStartg}>
            <InfoColumn>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                <Button2
                  big2
                  fontBig2
                  primary2={primary2}
                  onClick={onClickYoutube}
                >
                  Probar la demo
                </Button2>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn2>
              <ImgWrapper>
                <PlayIconWrapper onClick={onClickYoutube}>
                  <StyledImg src={img} alt={alt} />
                  <PlayIcon>▶</PlayIcon> {/* Ícono de reproducción */}
                </PlayIconWrapper>
              </ImgWrapper>
            </InfoColumn2>
          </InfoRow>
        </Container>
      </InfoSec>

      {/* Modal */}
      {isModalOpen && (
        <div id="modalBackground" onClick={handleClickOutside} style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            width: '90%',
            maxWidth: '600px',
            position: 'relative',
          }}>
            <button onClick={closeModal} style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
            }}>&times;</button>
            <div style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%', // Relación de aspecto 16:9
              background: '#000', // Opcional: fondo para que se vea mientras carga
            }}>
              <iframe
                src="https://www.youtube.com/embed/BR4mThVWbcQ?si=NUw4QUmdQ9-frzmR"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>


          </div>
        </div>
      )}
    </>
  );
};

export default InfoSection;